import { useOurNuxtApp } from "~/utils/nuxt";
import useNotify from "~/composables/useNotify";
import { createMutation, invalidateQueries } from "~/utils/queryUtils";
import FindToken from "~/utils/tokenPathFinder";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type { SendTestEmailPayload } from "~/server/api/cm/emails.post";
import type { Dictionary } from "~/utils/typeHelpers";
import type { BackendBookingViewModel } from "../models/Case/Booking.viewmodel";
import type { ScreenResultViewModel } from "../models/Case/Screen.model";
import type { Email } from "../models/Email/Email.model";
import type { EmailTemplate } from "../models/Email/EmailTemplate.model";
import { useQueryClient } from "@tanstack/vue-query";
import { InvalidationKeys } from "~/utils/queryInvalidators";

const cleanRecipients = (recipients: string) =>
  recipients.split(",").map((email) => {
    return { address: email.trim() };
  });

const mapAdditionalTemplateRequirements = (
  template: EmailTemplate,
  screenResult: ScreenResultViewModel | undefined,
  booking: BackendBookingViewModel | null | undefined
) => {
  if (!booking) return;
  const additionalParameters: Dictionary<Dictionary<string>> = {};
  if (template.includeHits && screenResult && screenResult?.hits) {
    const key = "Hits";
    const matchFields = screenResult.hits.flatMap((hit) =>
      hit.matches.map((match) => match.field)
    );
    additionalParameters[key] = extractFromBooking(matchFields, booking);
  }

  if (template.includedBookingProperties.length) {
    const key = "Details";
    additionalParameters[key] = extractFromBooking(
      template.includedBookingProperties,
      booking,
      true
    );
  }
  return additionalParameters;
};

const extractFromBooking = (
  paths: string[],
  booking: BackendBookingViewModel,
  replacePeriods = false
) => {
  const mappedMatches: Dictionary<string> = {};

  paths.map((field) => {
    const matchText = FindToken(field, booking);
    if (matchText) {
      let key = field;
      if (replacePeriods) key = field.replace(/\./g, "-");
      mappedMatches[key] = matchText as string;
    }
  });
  return mappedMatches;
};

export const getPayload = ({
  recipients,
  emailTemplate,
  message,
  screenResult,
  booking,
}: {
  recipients: string;
  emailTemplate: EmailTemplate;
  message?: string;
  screenResult?: ScreenResultViewModel;
  booking?: BackendBookingViewModel;
}) => {
  return {
    templateId: emailTemplate.id,
    recipients: cleanRecipients(recipients),
    message: message,
    additionalBookingProperties: mapAdditionalTemplateRequirements(
      emailTemplate,
      screenResult,
      booking
    ),
  };
};

const endpoints = {
  send: parseUriTemplate("/api/cm/case/{id}/emails"),
  test: "/api/cm/emails",
  preview: parseUriTemplate("/api/cm/case/{id}/emails/preview"),
} as const;

export const useEmailService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  const sendCaseEmail = (
    id: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) =>
    $api(endpoints.send.expand({ id }), {
      method: "POST",
      body: getPayload({
        recipients: payload.recipients,
        emailTemplate: payload.emailTemplate,
        message: payload.message,
        screenResult: screenResult,
        booking: booking,
      }),
    });

  const useSendCaseEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => sendCaseEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError(t("error.sending_email")),
        onSuccess: (_, { caseId }) => {
          invalidateQueries(
            InvalidationKeys.Case.sendEmail(caseId),
            queryClient
          );
          notifySuccess(t("success.success"), t("success.email_sent"));
        },
      }
    );

  // This is SEND on the [id] modal
  function sendTestEmail(payload: SendTestEmailPayload) {
    return $api(endpoints.test, {
      method: "POST",
      body: payload,
    });
  }

  const useSendTestEmailMutation = () =>
    createMutation((payload: SendTestEmailPayload) => sendTestEmail(payload), {
      onSuccess: () =>
        notifySuccess(t("success.success"), t("success.email_sent")),
      onError: () => notifyError(t("error.sending_email")),
    });

  const previewEmail = (
    id: string,
    payload: Email,
    screenResult?: ScreenResultViewModel,
    booking?: BackendBookingViewModel
  ) => {
    return $api(endpoints.preview.expand({ id }), {
      method: "POST",
      body: getPayload({
        recipients: payload.recipients,
        emailTemplate: payload.emailTemplate,
        message: payload.message,
        screenResult: screenResult,
        booking: booking,
      }),
    });
  };

  const usePreviewEmailMutation = () =>
    createMutation(
      ({
        caseId,
        payload,
        screenResult,
        booking,
      }: {
        caseId: string;
        payload: Email;
        screenResult?: ScreenResultViewModel;
        booking?: BackendBookingViewModel;
      }) => previewEmail(caseId, payload, screenResult, booking),
      {
        onError: () => notifyError(t("error.previewing_email")),
      }
    );

  return {
    useSendTestEmailMutation,
    useSendCaseEmailMutation,
    usePreviewEmailMutation,
  };
};
